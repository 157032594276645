<html lang="de">
<header>
  <meta charset="utf-8"/>
  <meta author="raphael">
</header>
<body>
<title>Thank you</title>
<div class="align-content-md-center">
  <img class="logo" src="../../assets/img/technik-and-fun-logo.png" width="100%"/>
  <h1>Herzlichen Glückwunsch zum Erfolgreichen Abschließen!</h1>
  <br>
  <h3>Bitte kehrt nun zum Ausgangspunkt zurück!</h3>
  <h3>Falls ihr die HTL nicht mehr findet drückt einfach den Knopf!</h3>
</div>
<div class="example-button-container center">
  <button
    (click)="googleMapsHtlSteyr()"  matTooltipClass="example-tooltip-red" matTooltip="Google Maps der Htl Steyr" mat-fab color="warn" aria-label="Example icon button with a home icon">
    <mat-icon>home</mat-icon>
  </button>
</div>
</body>
</html>
