import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';


@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css']
})
export class ThankyouComponent implements OnInit {

  constructor() {
  }


  googleMapsHtlSteyr() {
    window.open('https://www.google.at/maps/place/HTL+Steyr/@48.0490113,14.4263907,17z/data=!3m1!4b1!4m5!3m4!1s0x4773cf552965e69b:0x5477e4c88ef58d6e!8m2!3d48.0490077!4d14.4285794');
  }

  ngOnInit(): void {
    localStorage.removeItem('team');
    localStorage.removeItem('actual_question');
  }


}
