import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent} from './login/login.component';
import { TaskListComponent} from './task-list/task-list.component';
import {NotFoundComponentComponent} from './not-found-component/not-found-component.component';
import {ThankyouComponent} from './thankyou/thankyou.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'tasks', component: TaskListComponent},
  { path: '', component: LoginComponent},
  { path: 'thankyou', component: ThankyouComponent},
  { path: '404' , component: NotFoundComponentComponent},
  { path: '**', redirectTo: '404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
