import {Component, Inject, OnInit} from '@angular/core';
import {TaskService} from '../task.service';
import {TaskListComponent} from '../task-list/task-list.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-wrong-answer-popup-component',
  templateUrl: './wrong-answer-popup-component.component.html',
  styleUrls: ['./wrong-answer-popup-component.component.css']
})
export class WrongAnswerPopupComponentComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<WrongAnswerPopupComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any){}

  ngOnInit(): void {
  }

  requestHint(): void {
    this.data.taskService.sendHintRequest(this.data.team, this.data.question)
      .subscribe(hint => {
          if (hint.success) {
            /*this.data.question.hint = hint.hints[this.data.hintNr];
            this.data.hintrecieved = true;
            this.data.hintNr++;
            */
            console.log(hint);

            this.data.taskService.currentHint = hint.hints[this.data.taskService.hintNr];
            this.data.taskService.hintrecieved = true;
            this.data.taskService.hintNr++;

            console.log(this.data.taskService.currentHint);

            this.data.taskService.hintNr = this.data.taskService.hintNr % hint.hints.length;
          }
        }
      );
    this.dialogRef.close(this.data);
  }

  okClicked(): void{
    this.dialogRef.close(this.data);
  }

}
