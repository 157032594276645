import {Component, OnInit} from '@angular/core';
import {TaskService} from '../task.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.css']
})
export class ScannerComponent implements OnInit {
  scannerEnabled = true;
  currentDevice: MediaDeviceInfo = null;
  hasPermission: boolean;
  public formatsList = ['QR_CODE'];

  constructor(private taskService: TaskService, private router: Router) {
  }

  ngOnInit(): void {
  }

  scanSuccessHandler(result: string): void {
    console.log('Barcode found ' + result);

    if (result != null) {
      this.taskService.sendTeamCode(result).subscribe(response => {
        if (response.success) {
          if (!response.done) {
            localStorage.setItem('team', JSON.stringify(response.team));
            localStorage.setItem('actual_question', JSON.stringify(response.question));

            this.router.navigate(['/tasks']);
          } else {
            this.router.navigate(['/thankyou']);

          }
        }
      });
    }
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    console.log(devices);
    this.currentDevice = devices[0];
  }

  onHasPermission(has: boolean): void {
    this.hasPermission = has;
  }
}
