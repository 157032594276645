import {Component, OnInit, ViewChild} from '@angular/core';
import {Question} from '../Question';
import {TaskService} from '../task.service';
import {Team} from '../Team';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {WrongAnswerPopupComponentComponent} from '../wrong-answer-popup-component/wrong-answer-popup-component.component';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.css']
})
export class TaskListComponent implements OnInit {
  @ViewChild('answerText') answerText; // accessing the reference element
  question: Question;
  team: Team;

  // hintrecieved = false;
  // hint = false;
  // hintNr = 0;

  questionNr = 1;

  currentDevice: MediaDeviceInfo = null;
  hasPermission: boolean;
  public formatsList = ['QR_CODE'];
  showModal = false;

  constructor(public taskService: TaskService, private router: Router, private modalService: NgbModal, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.question = JSON.parse(localStorage.getItem('actual_question')) as Question;
    this.team = JSON.parse(localStorage.getItem('team')) as Team;
    if (this.question == null || this.team == null) {
      this.router.navigate(['/login']);
    }
  }

  previousTask(): void {
    this.taskService.sendAnswer(this.team, this.question, 'Antwort')
      .subscribe(nextQuestion => this.question = nextQuestion.question);
  }

  nextTask(answer: string): void {
    this.modalService.dismissAll();

    this.taskService.sendAnswer(this.team, this.question, answer)
      .subscribe(nextQuestion => {
        this.answerText.value = '';
        if (nextQuestion.success) {
          if (nextQuestion.done) {
            this.router.navigate(['/thankyou']);
          }

          this.question = nextQuestion.question;
          localStorage.setItem('actual_question', JSON.stringify(nextQuestion.question));
          this.taskService.hint = false;
          this.taskService.hintrecieved = false;
          this.taskService.hintNr = 0;
          window.location.href = '/';
        } else {
          const dialogRef = this.dialog.open(WrongAnswerPopupComponentComponent, {
            height: '400px',
            width: '600px',
            data: {
              team: this.team,
              question: this.question,
              hintNr: this.taskService.hintNr,
              hintrecieved: this.taskService.hintrecieved,
              taskService: this.taskService
            }
          });

          dialogRef.afterClosed().subscribe(data => {
            /*this.question = data.question;
            this.hintNr = data.hintNr;
            this.hintrecieved = data.hintrecieved;

            alert(this.hintrecieved);*/
          });

          this.taskService.hint = true;
        }

        this.answerText.nativeElement.value = ' ';
      });
  }

  logout(): void {
    this.question = null;
    this.team = null;
    localStorage.setItem('actual_question', null);
    localStorage.setItem('team', null);
    this.router.navigate(['/login']);
  }

  onHasPermission(has: boolean): void {
    this.hasPermission = has;
  }

  scanSuccessHandler(result: string): void {
    console.log('Barcode found ' + result);

    if (result != null) {
      this.nextTask(result);
    }
  }

  open(content): void {
    this.showModal = true;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.showModal = false;
    }, (reason) => {
      this.showModal = false;
    });
  }
}
