import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Question} from './Question';
import {Team} from './Team';
import {TeamCodeResponse} from './TeamCodeResponse';
import {AnswerResponse} from './AnswerResponse';
import {HintResponse} from './HintResponse';
import {Hint} from './Hint';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  hintrecieved = false;
  hint = false;
  hintNr = 0;
  currentHint: Hint;


  host = 'https://rallye.technik-and-fun.at/backend';

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };
  constructor(
    public http: HttpClient
  ) {
  }

  /**
   * Client scannt Code -> Server sendet aktuelle Frage
   * Client sendet Teamid + Antwort -> Server antwortet mit nächster Frage
   * Client sendet Teamid + Hinweisanfrage -> Server antwortet mit Hinweis
   *
   * Client speichert sich TeamID und sendet Sie bei jeder Aktion mit, damit
   * sich Server auskennt mit welchen Team er spricht
   */

  sendTeamCode(code: string): Observable<TeamCodeResponse> {
    console.log(code);
    return this.http.get<TeamCodeResponse>(this.host + '/index.php?action=login&hash=' + code, this.httpOptions);
  }

  sendAnswer(team: Team, question: Question, answer: string): Observable<AnswerResponse> {
    const obj = {questionId: question.id, text: answer};
    return this.http.post<AnswerResponse>(this.host + '/index.php?action=answer&hash=' + team.hash, obj, this.httpOptions);
  }

  sendHintRequest(team: Team, question: Question): Observable<HintResponse> {
    return this.http.post<HintResponse>(this.host + '/index.php?action=hint&hash=' + team.hash, question, this.httpOptions);
  }
}
