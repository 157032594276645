<img class="logo mb-2" src="../../assets/img/technik-and-fun-logo.png" width="100%"/>
<div class="card">
  <h2 class="card-header">Frage: {{question.number}}</h2>
  <div class="card-body">
    <h3 class="card-title">{{question.text}}</h3>
    <form class="card-text">
      <mat-form-field class="example-full-width">
        <mat-label>Eure Antwort</mat-label>
        <input matInput #answerText placeholder="Bitte eingeben" value="" />
      </mat-form-field>

      <div class="alert alert-warning" [ngClass]="{'invisible': !taskService.hintrecieved}" role="alert">
      <label
             [ngClass]="{'invisible': !taskService.hintrecieved}">Hinweis: {{taskService.currentHint !== undefined ? taskService.currentHint.text : ''}}</label>
      </div>
        <div class="buttons">
      <button type="button" class="btn btn-primary m-2" (click)="nextTask(answerText.value)">Weiter</button>
      <button type="button" class="btn btn-primary m-2" (click)="open(mymodal)">Antwort einscannen!</button>
</div>
    </form>
  </div>
</div>

<ng-template #mymodal let-modal>
  <div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title">Antwort einscannen!</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    </button>
  </div>
  <div class="modal-body">
    Scanne einfach den Antwort - QR Code ein.
    <zxing-scanner #scanner *ngIf="showModal" [formats]="formatsList" [enable]="true" [(device)]="currentDevice"
                   (permissionResponse)="onHasPermission($event)"
                   (scanSuccess)="scanSuccessHandler($event)"></zxing-scanner>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Abbrechen</button>
  </div>
</ng-template>




<br/>

<button type="button" class="btn btn-danger" (click)="logout()">Logout</button>
