import {Component, OnInit} from '@angular/core';
import {Question} from '../Question';
import {Router} from '@angular/router';
import {Team} from '../Team';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  constructor(private router: Router) {
    const q: Question = JSON.parse(localStorage.getItem('actual_question')) as Question;
    const t: Team = JSON.parse(localStorage.getItem('team')) as Team;

    console.log(t);
    console.log(q);

    if (q != null && t != null) {
      this.router.navigate(['/tasks']);
    }
  }

  ngOnInit(): void {
  }
}
